import { get } from 'lodash';
import maIntegrationKit from './ma-integration-kit';
import { isLayoutProGallery } from '@wix/communities-blog-client-common';
import mapStyleParamsToPG from './map-pg-layout-styles';
import { blogAppDefId } from '../constants/apps';
import { TPA_PAGE_ID_POST } from '../constants/tpa-pages';
import { getStyleProperty, HOMEPAGE_LAYOUT_TYPE_STYLE_PARAM_FULL } from '../constants/style-params';
import bi from './bi';
import monitoring from './monitoring';
import page from './page';
import { openModalPanel } from './modal';
import { BLOG_WIDGET_ID, POST_WIDGET_ID } from '../constants/widgets';
import retry from './retry';
import { getComponnetRef } from './magic-migration/sdk-utils';

export const initProvision = async ({ sdk }) => {
  try {
    await monitoring.toMonitored('sdk.document.save', retry(sdk.document.save, 5, 100), true);
    return true;
  } catch (_) {
    return false;
  }
};

export const initBiService = async ({ sdk, appToken }) => {
  bi.init(await sdk.document.info.getAppInstance(appToken));
};

export const displayProvisioningModal = async ({ sdk, appToken, isADI, membersAreaWriterProfileOnlyEnabled }) => {
  try {
    bi.provisioningModalDisplayed();
    const appData = await sdk.tpa.app.getDataByAppDefId(appToken, blogAppDefId);
    const url = get(appData, 'appFields.platform.baseUrls.provisioningModalUrl', '');
    const modal = { url, width: 744, height: 448, shouldHideHeader: true };
    const { action, value } = await openModalPanel({ sdk, appToken, isADI, modal });
    bi.provisioningModalClosed(action, value);

    const shouldInstallMembersArea = action === 'next' && value;
    if (shouldInstallMembersArea) {
      const canInstallWriterPage = await maIntegrationKit.canInstallBlogWriterProfilesOnly(sdk);
      const blogWriterProfilesOnly = membersAreaWriterProfileOnlyEnabled && canInstallWriterPage;
      await maIntegrationKit.installMembersArea({ blogWriterProfilesOnly });
    }
  } catch (error) {
    console.warn(error);
  }
};

export const setStyleParams = async ({ sdk, appToken, isADI, isClassicEditor }) => {
  if (isADI) {
    return;
  }

  const blogAppData = await sdk.tpa.app.getDataByAppDefId(appToken, blogAppDefId);
  const blogAppComponents = await sdk.document.tpa.app.getAllCompsByApplicationId(appToken, blogAppData.applicationId);
  if (!blogAppComponents) {
    return;
  }

  const blogComponent = blogAppComponents.find((component) => component.widgetId === BLOG_WIDGET_ID);
  if (!blogComponent) {
    return;
  }
  const blogComponentRef = await sdk.document.components.getById(appToken, { id: blogComponent.id });
  const blogComponentStyle = await sdk.components.style.get(appToken, { componentRef: blogComponentRef });

  const homepageLayoutType = getStyleProperty(blogComponentStyle, HOMEPAGE_LAYOUT_TYPE_STYLE_PARAM_FULL);

  if (isLayoutProGallery(homepageLayoutType)) {
    return;
  }

  const styleParams = mapStyleParamsToPG(blogComponentStyle, isClassicEditor);

  if (styleParams.length < 1) {
    return;
  }

  await sdk.document.tpa.setStyleParams(appToken, {
    compRef: blogComponentRef,
    styleParams,
  });
};

export const openPostPageSettings = async (sdk, appToken, title) => {
  const widgetId = POST_WIDGET_ID;
  const appData = await sdk.tpa.app.getDataByAppDefId(appToken, blogAppDefId);
  const instance = await sdk.document.info.getAppInstance();
  const componentRef = await getComponnetRef(sdk, widgetId);
  const appSettingsUrl = appData.widgets[widgetId].settings.urlV2;
  const url = `${appSettingsUrl}?instance=${instance}&appDefinitionId=${blogAppDefId}&compId=${componentRef.id}`;

  const blogPage = await page.find({ sdk, appToken, tpaPageId: TPA_PAGE_ID_POST });
  await sdk.document.pages.navigateTo(appToken, { pageRef: blogPage });

  sdk.editor.openComponentPanel(appToken, {
    url,
    title,
    type: sdk.editor.PanelType.Settings,
    componentRef,
    width: 404,
    height: appData.settingsHeight,
  });
};

export const openBlogPagesPanel = async ({ sdk, appToken }) => {
  const { check, show } = sdk.editor.deeplink;
  const pagesPanel = { type: 'pagesPanel', params: [blogAppDefId] };

  const deepLinkToBlogPagesPanel = await check(appToken, pagesPanel);
  deepLinkToBlogPagesPanel && (await show(appToken, pagesPanel));
};

export default {
  initProvision,
  initBiService,
  displayProvisioningModal,
  setStyleParams,
  openPostPageSettings,
  openBlogPagesPanel,
};
